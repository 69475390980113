<template>
    <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xs" :width="$vuetify.breakpoint.thresholds.sm" scrollable persistent>
        <template v-slot:activator="{ on }">
            <v-badge :value="hasProposals" overlap>
                <template v-slot:badge>
                    <span>{{ proposals }}</span>
                </template>
                <v-btn icon v-on="on" :disabled="!hasProposals || !hasProposalPermission">
                    <v-icon>mdi-format-list-checkbox</v-icon>
                </v-btn>
            </v-badge>
        </template>
        <wiki-proposals-core :value="value" @close="handleClose" @proposals="handleProposals" />
    </v-dialog>
</template>

<script>
// import WikiProposalsCore from './WikiProposalsCore';

import WikiMixin from '../Mixins/WikiMixin';

export default {
    name: 'wiki-proposals-menu',
    components: { WikiProposalsCore: () => import('./WikiProposalsCore.vue') },
    mixins: [WikiMixin],
    data: () => ({
        dialog: false,
        proposals: 0,
        hasProposalPermission: false
    }),
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (!!value && Object.keys(value).length > 0 && value.hasOwnProperty('id')) {
                    this.GET_PROPOSALS(value).then((response) => (this.proposals = response.length));
                }
            }
        }
    },
    computed: {
        hasProposals() {
            return this.proposals > 0;
        }
    },
    methods: {
        handleProposals(value) {
            this.proposals = value;
        },
        handleClose() {
            this.dialog = false;
        }
    },
    mounted() {
        this.validateEditorState({ proposals: ['MANAGE_PROPOSAL_WIKI'] }).then((response) => {
            this.hasProposalPermission = Object.values(response).reduce((result, next) => result && next, true);
        });
    }
};
</script>
